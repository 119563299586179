
import React from 'react';

const AnalyticsPage = () => {
    return (
        'GooGhywoiu9839t543j0s7543uw1, Email address- rafaela.camara@ifood.com.br, GA Properties- G-PX4DH8Z3VX and UA-175442070-1 with Admin level Access Permission - Date 28/3/2024'
    )
}

export default AnalyticsPage
